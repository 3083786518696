@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply grid place-items-center text-base sm:text-sm font-medium border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed h-auto w-full py-2 px-4;
  }
  .btn-primary {
    @apply bg-primary-600 disabled:hover:bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 text-white;
  }
}

.react-kanban-board {
  padding: 0 !important;
}

.react-kanban-column > div:nth-of-type(2) {
  max-height: 80vh !important;
  min-height: 80vh !important;
  overflow-y: auto !important;
  overflow-y: scroll;
  padding-bottom: 100px;
}
.react-kanban-column {
  width: 260px;
  background: #f3f4f6 !important;
  padding: 10px !important;
}

div[data-rbd-scroll-container-context-id] {
  background: #f9fafb !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#phone-input {
  height: 38px;
  border-radius: 4px;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  font-size: 14px;
}
